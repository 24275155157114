<template>
  <v-card
    class="pa-6 d-flex flex-column"
    style="width: 380px; gap: 20px; background: var(--v-background_light-base)"
    flat
    rounded="xl"
  >
    <div class="d-flex flex-column" style="gap: 4px">
      <div class="title-1 font-weight-bold onSurface_dark--text">
        온라인 채팅 상담
      </div>
      <div class="body-2 font-weight-regular onSurface_light--text">
        운영 시간 : 평일 10~17시 <br />영업 시간 외에도 상담은 진행되나 답변이
        늦을 수 있습니다.
      </div>
    </div>
    <div
      class="d-flex flex-column justify-center"
      style="border-top: 1px solid var(--v-onSurface_normal-base); height: 100%"
    >
      상담원과 ChatGPT의 결합으로 <br />
      24시간 끊이지 않는 상담 시스템이 준비중에 있습니다.
    </div>
    <div class="d-flex flex-column" style="gap: 4px">
      <div
        class="pa-3"
        style="
          background: var(--v-background_normal-base);
          height: 80px;
          border-radius: 8px;
        "
      >
        시스템 준비중입니다. <br />
        우측 하단의 기존 채팅 시스템을 이용해 주세요!
      </div>
      <div class="d-flex justify-space-between">
        <v-btn icon height="28px" small>
          <feather type="paperclip" size="20" />
        </v-btn>
        <v-btn color="primary" min-width="52px" max-height="28px" class="pa-0">
          <feather type="send" size="16" />
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {};
</script>

<style></style>
