<template>
  <v-card
    class="pa-6 d-flex flex-column"
    style="
      gap: 20px;
      height: 100%;
      background: var(--v-background_light-base);
      width: 100%;
    "
    flat
    rounded="xl"
  >
    <div class="title-1 font-weight-bold onSurface_dark--text">주문서</div>
    <div class="px-3 d-flex flex-column" style="gap: 40px">
      <div
        class="d-flex justify-space-between"
        :class="$vuetify.breakpoint.mdAndDown && 'flex-column'"
        style="gap: 40px"
      >
        <div class="d-flex flex-column" style="gap: 16px; width: 420px">
          <div class="title-2 font-weight-bold onSurface_dark--text">
            기본 정보
          </div>
          <div class="px-3 d-flex flex-column" style="gap: 12px">
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                사용 목적
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.purpose }}
              </div>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                개인 정보
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.displayName + " | " + orderData.phoneNumber }}
              </div>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                주문 상태
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ status.find((v) => v.title === orderData.status)?.text }}
              </div>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                접수 일시
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.createdAt | moment("YYYY-MM-DD HH:mm") }}
              </div>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                결제 일시
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.paidAt || "결제 전" | moment("YYYY-MM-DD HH:mm") }}
              </div>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                최근 수정
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.updatedAt | moment("YYYY-MM-DD HH:mm") }}
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column" style="gap: 16px; width: 420px">
          <div class="title-2 font-weight-bold onSurface_dark--text">
            결제 정보
          </div>
          <div class="px-3 d-flex flex-column" style="gap: 12px">
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                3D모델링
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.modelingPrice.toLocaleString() }}
              </div>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                3D프린팅
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.printingPrice.toLocaleString() }}
              </div>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                배송비
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{
                  orderData.shippingMethod +
                  " | " +
                  orderData.shippingPrice.toLocaleString()
                }}
              </div>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                금액 조정
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.discountPrice.toLocaleString() }}
              </div>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                총 결제액
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.totalPrice.toLocaleString() }}
              </div>
            </div>
            <div
              class="d-flex justify-space-between align-center"
              v-if="orderData.paidAt"
            >
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                결제 방식
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.paymentMethod }}
              </div>
            </div>
            <div class="d-flex justify-space-between" v-else>
              <div
                class="d-flex subtitle-1 font-weight-regular onSurface_normal--text align-center"
                style="height: 34px"
              >
                결제 방법
              </div>
              <div class="d-flex" v-if="!quoteChecked">
                <v-btn
                  width="260px"
                  elevation="0"
                  color="primary"
                  @click="
                    $toasted.global.notice(
                      '하단의 결제 전 확인 사항을 우선 체크해주세요!'
                    )
                  "
                >
                  결제 안내받기
                </v-btn>
              </div>
              <div class="d-flex flex-column" style="gap: 8px" v-else>
                <div
                  class="px-3 py-2 d-flex flex-column subtitle-2 font-weight-regular"
                  style="
                    border-radius: 4px;
                    border: 1px solid var(--v-background_dark-base);
                    max-width: 260px;
                    width: 260px;
                    gap: 4px;
                  "
                  @click="payAlert('네이버')"
                >
                  <div
                    class="d-flex justify-space-between onSurface_normal--text"
                  >
                    <div>네이버 결제</div>
                    <div>결제 안내 +</div>
                  </div>
                  <div class="d-flex onSurface_light--text">
                    카드 결제, 현금영수증 발행이 가능합니다.
                  </div>
                </div>
                <div
                  class="px-3 py-2 d-flex flex-column subtitle-2 font-weight-regular"
                  style="
                    border-radius: 4px;
                    border: 1px solid var(--v-background_dark-base);
                    max-width: 260px;
                    width: 260px;
                    gap: 4px;
                  "
                  @click="payAlert('전자세금계산서')"
                >
                  <div
                    class="d-flex justify-space-between onSurface_normal--text"
                  >
                    <div>기업 및 기관</div>
                    <div>결제 안내 +</div>
                  </div>
                  <div class="d-flex onSurface_light--text">
                    전자세금계산서 발행으로 진행됩니다.
                  </div>
                </div>
                <div
                  class="px-3 py-2 d-flex flex-column subtitle-2 font-weight-regular"
                  style="
                    border-radius: 4px;
                    border: 1px solid var(--v-background_dark-base);
                    max-width: 260px;
                    width: 260px;
                    gap: 4px;
                  "
                  @click="payAlert('현금')"
                >
                  <div
                    class="d-flex justify-space-between onSurface_normal--text"
                  >
                    <div>현금 결제</div>
                    <div>결제 안내 +</div>
                  </div>
                  <div class="d-flex onSurface_light--text">
                    일반 개인 현금 결제입니다.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-space-between"
        :class="$vuetify.breakpoint.mdAndDown && 'flex-column'"
        style="gap: 40px"
        v-if="orderData.modelingFile.length > 0"
      >
        <div class="d-flex flex-column" style="gap: 16px; width: 420px">
          <div class="title-2 font-weight-bold onSurface_dark--text">
            3D모델링
          </div>
          <div class="px-3 d-flex flex-column" style="gap: 12px">
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                완료일(예정)
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.modelingDate || "확인 전" }}
              </div>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                예상 출력비
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.modelingExpectPrice || "확인 전" }}
              </div>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                참고 사항
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.modelingMemo }}
              </div>
            </div>
            <div class="d-flex flex-column justify-space-between">
              <div
                class="d-flex subtitle-1 font-weight-regular onSurface_normal--text align-center"
                style="height: 34px"
              >
                첨부 파일
              </div>
              <v-row dense>
                <v-col
                  cols="12"
                  v-for="item in orderData.modelingFile"
                  :key="item.name"
                >
                  <div
                    class="d-flex pa-2"
                    style="
                      border-radius: 4px;
                      gap: 8px;
                      cursor: pointer;
                      border: 1px solid var(--v-background_dark-base);
                    "
                  >
                    <div>
                      <v-skeleton-loader
                        v-if="!previewImage(item)"
                        style="width: 40px; height: 40px"
                        type="image"
                      />
                      <v-img
                        v-else
                        style="border-radius: 4px"
                        :src="previewImage(item)"
                        max-width="40"
                        max-height="40"
                      />
                    </div>
                    <div
                      class="d-flex flex-column justify-space-around"
                      style="
                        gap: 8px;
                        white-space: nowrap;
                        overflow: hidden;
                        width: 100%;
                      "
                    >
                      <div
                        class="subtitle-2 font-weight-bold onSurface_dark--text"
                      >
                        {{ item.name }}
                      </div>
                      <div
                        class="d-flex justify-space-between subtitle-2 font-weight-regular onSurface_dark--text"
                      >
                        <div>
                          {{
                            item.size >= 1024 * 1024
                              ? (item.size / 1024 / 1024).toFixed(2) + "MB"
                              : (item.size / 1024).toFixed(0) + "KB"
                          }}
                        </div>
                        <div>
                          {{ item.type.toUpperCase() }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="d-flex flex-column justify-space-between">
            <div
              class="d-flex subtitle-1 font-weight-regular onSurface_normal--text align-center"
              style="height: 34px"
            >
              확인 사항
            </div>
            <div
              class="px-2 d-flex subtitle-2 font-weight-bold onSurface_normal--text align-center"
              style="height: 34px"
            >
              <feather
                type="check-square"
                size="18"
                class="pr-2"
                stroke="var(--v-primary-base)"
              />
              (필독) 결제 전 꼭 확인해야 하는 3D 모델링 유의사항!
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-space-between"
        :class="$vuetify.breakpoint.mdAndDown && 'flex-column'"
        style="gap: 40px"
      >
        <div class="d-flex flex-column" style="gap: 16px; width: 420px">
          <div class="title-2 font-weight-bold onSurface_dark--text">
            3D프린팅
          </div>
          <div class="px-3 d-flex flex-column" style="gap: 12px">
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                작업 장소
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.workspace }}
              </div>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                희망 출고일
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.dueAt | moment("YYYY-MM-DD") }}
              </div>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                출고일
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.shipAt || "출고 전" }}
              </div>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                참고 사항
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.memo }}
              </div>
            </div>
            <div class="d-flex flex-column justify-space-between">
              <div
                class="d-flex subtitle-1 font-weight-regular onSurface_normal--text align-center"
                style="height: 34px"
              >
                출력 파일
              </div>
              <div class="d-flex flex-column" style="overflow: auto; gap: 4px">
                <div
                  v-for="item in orderData.file"
                  :key="item.name"
                  class="pa-2 div-border flex-column"
                  style="gap: 8px"
                >
                  <div class="d-flex" style="gap: 12px">
                    <div>
                      <v-skeleton-loader
                        v-if="!previewImage(item)"
                        style="width: 48px; height: 36px"
                        type="image"
                      />
                      <v-img
                        v-else
                        style="border-radius: 4px"
                        :src="previewImage(item)"
                        max-width="48"
                        max-height="36"
                      />
                    </div>
                    <div
                      class="d-flex flex-column"
                      style="width: 100%"
                      :style="
                        $vuetify.breakpoint.mdAndDown ? 'gap: 4px' : 'gap: 8px'
                      "
                    >
                      <div
                        class="subtitle-2 font-weight-bold onSurface_dark--text"
                        style="cursor: pointer"
                        @click="open(item.url)"
                      >
                        {{ item.name }}
                      </div>
                      <div
                        class="caption font-weight-regular onSurface_light--text"
                      >
                        X: {{ item.dimensions.x }}, Y: {{ item.dimensions.y }},
                        Z: {{ item.dimensions.z }} (mm)
                      </div>
                    </div>
                  </div>
                  <div class="px-2 d-flex flex-column" style="gap: 4px">
                    <div
                      v-for="(option, i) in item.option"
                      :key="i"
                      class="d-flex flex-column onSurface_normal--text subtitle-2"
                    >
                      <div class="d-flex justify-space-between">
                        <div>
                          {{
                            `${option.method} / ${option.material} / ${option.color} (${option.layer} / ${option.wall} / ${option.infill})`
                          }}
                        </div>
                        <div>
                          {{ `개당 ${option.price.toLocaleString()}원` }}
                        </div>
                      </div>
                      <div class="d-flex justify-space-between pl-2">
                        <div>
                          {{
                            `(완료 ${option.finishQty}개) 총 ${option.totalQty}개`
                          }}
                        </div>
                        <div>
                          {{
                            `총 ${(
                              option.price * option.totalQty
                            ).toLocaleString()}원`
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div
                class="subtitle-1 font-weight-regular onSurface_normal--text"
              >
                작업 메모
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-regular onSurface_normal--text align-center"
                style="
                  border-radius: 4px;
                  border: 1px solid var(--v-background_dark-base);
                  max-width: 260px;
                  width: 260px;
                  height: 34px;
                "
              >
                {{ orderData.printmemo }}
              </div>
            </div>
            <div
              class="d-flex flex-column justify-space-between"
              v-if="!orderData.paidAt"
            >
              <div
                class="d-flex subtitle-1 font-weight-regular onSurface_normal--text align-center"
                style="height: 34px"
              >
                확인 사항
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-bold onSurface_normal--text align-center"
                style="height: 34px"
              >
                <feather
                  :type="quoteChecked ? 'check-square' : 'square'"
                  size="18"
                  class="pr-2"
                  :stroke="
                    quoteChecked
                      ? 'var(--v-primary-base)'
                      : 'var(--v-onSurface_normal-base)'
                  "
                  @click="quoteChecked = !quoteChecked"
                />
                채팅에 안내된 사항과 유의사항을 모두 확인하였습니다.
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column" style="gap: 16px; width: 420px">
          <div class="title-2 font-weight-bold onSurface_dark--text">
            프린팅 결과
          </div>
          <div class="px-3 d-flex flex-column" style="gap: 12px">
            <div class="d-flex flex-column justify-space-between">
              <div
                class="d-flex subtitle-1 font-weight-regular onSurface_normal--text align-center"
                style="height: 34px"
              >
                출력물 사진은 채팅에서 확인 가능합니다. (시스템 준비중)
              </div>
              <v-row dense>
                <v-col
                  cols="4"
                  v-for="item in orderData.resultImg?.filter(
                    (v) => v.type != 'stl'
                  )"
                  :key="item.name"
                >
                  <div
                    class="d-flex"
                    style="
                      border-radius: 4px;
                      gap: 8px;
                      cursor: pointer;
                      border: 1px solid var(--v-background_dark-base);
                    "
                  >
                    <v-img
                      style="border-radius: 4px"
                      :src="previewImage(item)"
                      contain
                      :aspect-ratio="4 / 3"
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
            <!-- <div class="d-flex flex-column justify-space-between">
              <div
                class="d-flex subtitle-1 font-weight-regular onSurface_normal--text align-center"
                style="height: 34px"
              >
                확인 사항
              </div>
              <div
                class="px-2 d-flex subtitle-2 font-weight-bold onSurface_normal--text align-center"
                style="height: 34px"
              >
                <feather
                  type="check-square"
                  size="18"
                  class="pr-2"
                  stroke="var(--v-primary-base)"
                />
                (필독) 출고 전 사진으로 꼭 검토해야 하는 사항들!
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <v-card-actions>
      <v-btn
        color="error"
        elevation="0"
        :loading="btnLoading"
        @click="
          alert = {
            dialog: true,
            msg: '해당 기능은 되돌릴 수 없습니다. \n 주문서가 완전히 삭제됩니다.',
            confirmMsg: '삭제',
            cancelMsg: '취소',
            onConfirm: () => {
              deleteOrder(orderData);
            },
            onCancel: () => {},
          }
        "
      >
        주문서 삭제
      </v-btn>
      <v-spacer />
      <v-btn elevation="0" @click="editOrder(orderData)"> 주문서 수정</v-btn>
    </v-card-actions>
    <WarningDialog
      :msg="alert"
      :visible.sync="alert.dialog"
      @confirm="alert.onConfirm"
      @cancel="alert.onCancel"
    />
  </v-card>
</template>

<script>
import ORDER from "@/API/order.js";

export default {
  name: "MyPage-Order",
  props: {},
  data() {
    return {
      unsubscribe: null,
      orderData: null,
      status: [
        { icon: "edit-3", title: "Draft", text: "접수 전" },
        { icon: "upload-cloud", title: "Pending", text: "신규 주문" },
        { icon: "user-check", title: "Checking", text: "확인 중" },
        { icon: "repeat", title: "Hold", text: "수정 요청" },
        { icon: "credit-card", title: "Ready", text: "결제 확인" },
        { icon: "tool", title: "Progress", text: "작업 진행" },
        { icon: "check-square", title: "Finished", text: "작업 완료" },
        { icon: "truck", title: "Shipped", text: "출고 완료" },
      ],
      alert: {
        dialog: false,
        msg: "",
        confirmMsg: "",
        cancelMsg: "",
        onConfirm: () => {},
        onCancel: () => {},
      },
      quoteChecked: false,
      btnLoading: false,
    };
  },
  mounted() {
    if (this.$router.currentRoute.query.id)
      this.unsubscribe = ORDER.getOrder(
        this.$router.currentRoute.query.id,
        (order) => {
          if (order) this.orderData = order;
        }
      );
  },
  beforeDestroy() {
    if (typeof this.unsubscribe === "function") {
      this.unsubscribe();
    }
  },
  methods: {
    payAlert(method) {
      switch (method) {
        case "네이버":
          this.alert = {
            icon: "bell",
            dialog: true,
            msg:
              "아래 링크에서 안내된 총 금액에 맞춰 수량을 조절하여 결제합니다. <br/> <br/>총" +
              this.orderData.totalPrice.toLocaleString() +
              "원 ⇒ 수량 " +
              (this.orderData.totalPrice / 100).toLocaleString() +
              "개로 결제 <br/><br/> 네이버 결제 시 작성된 배송지로 물건이 배송됩니다. <br/> 결제 후 메세지를 남겨주세요!",
            confirmMsg: "결제 링크",
            cancelMsg: "취소",
            onConfirm: () => {
              window.open(
                "https://smartstore.naver.com/madeall/products/2215031974"
              );
            },
            onCancel: () => {},
          };
          break;
        case "전자세금계산서":
          this.alert = {
            icon: "bell",
            dialog: true,
            msg: "국민은행 300101-04-099501 주식회사 메이드올로 이체 해주세요. <br/> 이체 후, 계산서 발행을 위한 정보를 남겨주세요. <br/> 사업자등록증 사본 & 계산서 발행 이메일 주소",
            confirmMsg: "확인",
            cancelMsg: "취소",
            onConfirm: () => {},
            onCancel: () => {},
          };
          break;
        case "현금":
          this.alert = {
            icon: "bell",
            dialog: true,
            msg: "카카오뱅크 7979-03-81358 구지헌으로 이체 해주세요. <br/> 이체 후, 메세지를 남겨주세요.",
            confirmMsg: "확인",
            cancelMsg: "취소",
            onConfirm: () => {},
            onCancel: () => {},
          };
          break;
        default:
      }
    },
    previewImage(item) {
      if (item.previewImg) {
        return item.previewImg;
      } else {
        const fileType = item.name
          .slice(item.name.lastIndexOf(".") + 1)
          .toLowerCase();
        if (["png", "jpg", "jpeg", "gif", "bmp", "webp"].includes(fileType)) {
          return item.url;
        }
        if (fileType == "pdf") {
          return "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FPDF_thumbnail.png?alt=media&token=1cc80cdb-6e88-432d-b7ba-e8a0b6ac8c80";
        }
        return null;
      }
    },
    open(url) {
      window.open(url);
    },
    async deleteOrder(order) {
      if (order.status === "Draft" || order.status === "Pending") {
        this.btnLoading = true;
        try {
          await ORDER.deleteOrder(order);

          this.$router.push({ path: "/mypage" });
        } catch (error) {
          console.error("Error deleting order:", error);
        } finally {
          this.btnLoading = false;
        }
      } else {
        this.$toasted.global.error("현재 상태에서는 직접 삭제가 불가합니다.");
      }
    },
    editOrder(order) {
      if (
        order.status == "Draft" ||
        order.status == "Pending" ||
        order.status == "Hold"
      ) {
        this.$router.push({ path: "/page/order", query: { id: order.id } });
      } else {
        this.$toasted.global.error("현재 상태에서는 직접 수정이 불가합니다.");
      }
    },
  },
};
</script>

<style scoped>
.div-border {
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--v-background_dark-base);
}
</style>
